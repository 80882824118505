<template>
  <section class="section container">
    <div class="columns is-centered box">
      <div class="column is-5">
        <form v-on:submit.prevent="register">
          <b-field label="NOME">
            <b-input type="text" v-model="user.name" required></b-input>
          </b-field>

          <div class="div is-flex-desktop">
            <b-field label="CPF" class="m-r-md" :type="erroForm.cpf">
              <b-input v-mask="['###.###.###-##']" v-model="user.cpf" required></b-input>
            </b-field>

            <b-field label="Data de Nascimento">
              <b-datepicker
                :month-names="datePicker[0]"
                :day-names="datePicker[1]"
                icon="calendar-alt"
                v-model="user.birthday"
              ></b-datepicker>
            </b-field>
          </div>

          <b-field label="CEP" :type="erroForm.cep">
            <b-input type="text" v-mask="['#####-###']" maxlength="9" v-model="cep" required></b-input>
          </b-field>

          <div class="is-flex-desktop">
            <b-field label="ESTADO" class="m-r-md" v-if="user.state">
              <b-input type="text" v-model="user.state" required disabled></b-input>
            </b-field>

            <b-field label="CIDADE" v-if="user.city">
              <b-input type="text" v-model="user.city" required></b-input>
            </b-field>
          </div>

          <b-field label="ENDREÇO" v-if="user.address">
            <b-input type="text" v-model="user.address" required></b-input>
          </b-field>

          <b-field label="BAIRRO" v-if="user.neighborhood">
            <b-input type="text" v-model="user.neighborhood" required></b-input>
          </b-field>
          
          <b-field label="NÚMERO" v-if="user.address">
            <b-input type="number" v-model="user.addressNumber" required></b-input>
          </b-field>

          <div class="is-flex-desktop">
            <b-field label="TELEFONE">
              <b-input
                class="m-r-md"
                type="text"
                v-mask="['(##) ####-####', '(##) #####-####']"
                v-model="user.phone"
              ></b-input>
            </b-field>

            <b-field label="CELULAR">
              <b-input
                type="text"
                v-mask="['(##) ####-####', '(##) #####-####']"
                v-model="user.cellPhone"
                required
              ></b-input>
            </b-field>
          </div>

          <b-field label="Email" :type="erroForm.email">
            <b-input type="email" v-model="user.email" required></b-input>
          </b-field>

          <b-field label="Senha" :type="erroForm.password">
            <b-input type="password" v-model="user.password" required></b-input>
          </b-field>

          <button class="button is-info is-fullwidth">Cadastrar</button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import Axios from "axios";

import { mask } from "vue-the-mask";
import { validateCPF } from "../../../assets/js/validatorCpf";
export default {
  directives: { mask },
  data() {
    return {
      erroForm: {
        cep: "",
        cpf: "",
        email: ""
      },
      datePicker: [
        [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ],
        ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]
      ],
      user: {
        username: "",
        name: "",
        password: "",
        cpf: "",
        email: "",
        birthday: null,
        state: "",
        city: "",
        addres: "",
        zipCode: "",
        phone: "",
        cellPhone: ""
      },
      cep: ""
    };
  },
  methods: {
    toNumber(data) {
      return data.replace(/[^\d]+/g, "");
    },
    register() {
      this.erroForm = {
        cep: "",
        cpf: "",
        email: "",
        password: ""
      };

      this.user.cpf = this.toNumber(this.user.cpf);
      if (validateCPF(this.user.cpf)) {
        this.user.username = this.user.email;
        this.user.addressNumber = +this.user.addressNumber
        this.user.cellPhone
          ? (this.user.cellPhone = this.toNumber(this.user.cellPhone))
          : "";
        this.user.phone
          ? (this.user.phone = this.toNumber(this.user.phone))
          : "";
        this.user.team = '5d264bccdc5cc0068bc3dcd3';
        Axios.post(`/v1/sign-up`, this.user)
          .then(data => {
            if (data.data) {
              this.$buefy.dialog.alert("Cadastro concluído com sucesso!");
              this.$router.push({ path: "/" });
            }
          })
          .catch(erro => {
            console.error(erro);

            for (const e of erro.response.data.error) {
              if (e.message[0].includes("cpf") && e.message[0].includes("exists")) {
                this.$buefy.dialog.alert("CPF já utlizado!");
                this.erroForm.cpf = "is-danger";
              } else if (
                e.message[0].includes("email") &&
                e.message[0].includes("exists")
              ) {
                this.$buefy.dialog.alert("Email já utlizado");
                this.erroForm.email = "is-danger";
              } else if (
                e.message[0].includes("email") &&
                e.message[0].includes("exists")
              ) {
                this.$buefy.dialog.alert("Email já utlizado");
                this.erroForm.email = "is-danger";
              } else if (
                e.message[0].includes("password") &&
                e.message[0].includes("minimum allowed length (6)")
              ) {
                this.$buefy.dialog.alert("Senha precisa ter ao menos 6 caracteres!");
                this.erroForm.password = "is-danger";
              }
            }
          });
      } else {
        this.erroForm.cpf = "is-danger";
        this.$buefy.dialog.alert("CPF inválido!");
      }
    },
    getCep() {
      let temp = [];
      temp[0] = Axios.defaults.headers.common["Authorization"];
      temp[1] = Axios.defaults.headers.post["Content-Type"];
      temp[2] = Axios.defaults.headers.common["cache-control"];
      delete Axios.defaults.headers.common["Authorization"];
      delete Axios.defaults.headers.post["Content-Type"];
      delete Axios.defaults.headers.common["cache-control"];
      Axios({
        method: "GET",
        url: `https://viacep.com.br/ws/${this.cep}/json/`,
        headers: {}
      })
        .then(data => {
          var address = data.data;
          this.user.zipCode = this.toNumber(address.cep);
          this.user.address = address.logradouro;
          this.user.city = address.localidade;
          this.user.state = address.uf;
          this.user.neighborhood = address.bairro;
          this.erroForm.cep = null;
          Axios.defaults.headers.common["Authorization"] = temp[0];
          Axios.defaults.headers.post["Content-Type"] = temp[1];
          Axios.defaults.headers.common["cache-control"] = temp[2];
        })
        .catch(erro => {
          console.error(erro);
          this.erroForm.cep = "is-danger";
          this.$buefy.dialog.alert("CEP inválido!");
          Axios.defaults.headers.common["Authorization"] = temp[0];
          Axios.defaults.headers.post["Content-Type"] = temp[1];
          Axios.defaults.headers.common["cache-control"] = temp[2];
        });
    }
  },
  watch: {
    cep(cep) {
      if (cep.length == 9) {
        this.getCep();
      }
    }
  }
};
</script>

<style>
</style>
